import { Component, OnInit, Renderer2 } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AlertService, AlertType, UserContextService } from '@elering/common';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, filter, finalize } from 'rxjs/operators';
import { CustomDashboardService } from './overrides/custom-dashboard.service';
import { UserMenuService } from './services/user-menu.service';
import { FooterService } from './services/footer.service';
import { SessionExpiryService } from './services/session-expiry.service';
import { LocaleService } from './services/locale.service';
import { CookiebotService } from './services/cookiebot.service';
import { EnergyCompaniesService } from './services/energy-companies.service';
import { AnalyticsService } from './services/analytics.service';
import { HealthResponse, HealthService } from '../../gen_openapi';
import { AppService } from './services/app.service';
import { GrantService } from './services/grant.service';
import { RoutingConstants } from './shared/constants/routing-constants';

@Component({
  selector: 'ws-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  showStaticErrorPage = false;
  showNotification = false;
  showApp = false;

  constructor(
    private translateService: TranslateService,
    private localeService: BsLocaleService,
    private userContextService: UserContextService,
    private titleService: Title,
    private userMenuService: UserMenuService,
    private footerService: FooterService,
    private activatedRoute: ActivatedRoute,
    private customDashboardService: CustomDashboardService,
    private sessionExpiryService: SessionExpiryService,
    private locale: LocaleService,
    private cookiebot: CookiebotService,
    private renderer: Renderer2,
    private energyCompaniesService: EnergyCompaniesService,
    private analytics: AnalyticsService,
    private router: Router,
    private alertService: AlertService,
    private location: Location,
    private healthService: HealthService,
    private appService: AppService,
    private grantService: GrantService
  ) {
  }

  ngOnInit(): void {
    this.showNotification = this.appService.appSettings.notification.enabled;
    this.energyCompaniesService.load().pipe(
      finalize(() => {
        if (this.isStaticErrorPageEnabled()) {
          this.healthService.getIntegrationsHealthInfo().subscribe({
            next: (healthResponse: HealthResponse) => {
              let allSystemsAreHealthy = healthResponse
                && healthResponse.tempHealthy
                && healthResponse.parmHealthy;

              if (this.isAvpHealthCheckEnabled()) {
                allSystemsAreHealthy = allSystemsAreHealthy
                  && healthResponse.gavpHealthy;
              }

              if (allSystemsAreHealthy) {
                this.init();
              } else {
                console.error(`Some Integration is not healthy. Health info response: ${JSON.stringify(healthResponse)}`);
                this.showStaticErrorPage = true;
              }
            },
            error: (err) => {
              console.error('Failed to get Integrations Health info', err);
              this.showStaticErrorPage = true;
            }
          });
        } else {
          this.init();
        }
      })
    ).subscribe();
  }

  private init(): void {
    this.customDashboardService.setDefaultDashboardConfig();
    this.customDashboardService.setSubHeader('');

    this.setupLocaleService();

    this.updatePageTitle();

    this.setupLanguageChangeListener();

    this.setupRepresentativeChangeListener();

    this.sessionExpiryService.init();

    this.cookiebot.init(this.renderer, this.translateService.currentLang);

    this.analytics.init(this.renderer);

    this.setupRouteChangeListenerToClearAlerts();

    this.showApp = true;
  }

  private updatePageTitle(): void {
    this.titleService.setTitle(this.translateService.instant('general.pageHeaderTitle'));
  }

  private setupLocaleService(): void {
    this.activatedRoute.queryParams.subscribe((queryParam) => {
      if (queryParam && queryParam['lang']) {
        this.translateService.use(queryParam.lang);
      }
    });

    this.localeService.use(this.translateService.currentLang);
    this.locale.registerLocale(this.translateService.currentLang);
  }

  private setupLanguageChangeListener(): void {
    this.translateService.onLangChange.subscribe((langEvent) => {
      this.cookiebot.changeLanguage(this.renderer, langEvent.lang);
      localStorage.setItem('language', langEvent.lang);
      this.updatePageTitle();
      this.locale.registerLocale(langEvent.lang);
      this.localeService.use(langEvent.lang);
    });
  }

  private setupRepresentativeChangeListener(): void {
    this.userContextService.$representationSubject.subscribe(() => {
      this.userMenuService.adjustMenuForUser();
      this.footerService.createFooterForUser();
      this.customDashboardService.setDefaultDashboardConfig();
    });
  }

  private setupRouteChangeListenerToClearAlerts(): void {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd), debounceTime(0)).subscribe(() => {
      const keep = !!this.location.getState()['keepAlerts'];
      if (!keep) {
        this.alertService.clearAll();
      }
    });
  }

  private isStaticErrorPageEnabled() {
    return this.appService.appSettings?.features?.staticErrorPageEnabled ?? true;
  }

  private isAvpHealthCheckEnabled() {
    return this.appService.appSettings?.features?.avpHealthCheckEnabled ?? true;
  }

  getMenusClass(): string {
    if (this.grantService.isGrantPage() || this.grantService.isRedirectingToGrantPage()
      || this.router.url.startsWith(`/${RoutingConstants.LOGOUT_PATH}`)) {
      return 'hideMenus';
    }
    return '';
  }

  notificationClosed() {
    this.showNotification = false;
  }

  getNotificationText() {
    return this.appService.appSettings.notification[this.translateService.currentLang];
  }

  getType(): AlertType {
    return this.appService.appSettings.notification.type;
  }
}
