import { AlertType } from '@elering/common';

export class Cookiebot {
    enabled: boolean;
    url: string;
    domainGroupId: string;
}

export class Features {
    energySellerPermissionsEnabled: boolean;
    accessibilityMenuEnabled: boolean;
    staticErrorPageEnabled: boolean;
    avpHealthCheckEnabled: boolean;
    helpButtonEnabled: boolean;
    accountSettingsEnabled: boolean;
    aggregatorPermissionsEnabled: boolean;
}

export class Notification {
    enabled: boolean;
    et: string;
    en: string;
    type: AlertType;
}

export class AppSettings {
    sessionExpiryWarningTimeout: number;
    sessionExpiryDialogTimeout: number;
    sessionExpiredDialogTimeout: number;
    cookiebot: Cookiebot;
    gaTrackingId: string;
    maxMeteringPointCountForMeteringDataMetrics: number;
    features: Features;
    notification: Notification;
    electricityCompanies: string[];
    onlyForLegalCompanies: string[];
    allowedGrantRedirectUris: string[];
    strictRedirectUris: boolean;
    allowedGrantEics: string[];
}
