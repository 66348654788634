import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, } from '@angular/core';
import { EnergyType, MeteringDataTimeInterval, MeteringResolution } from 'gen_openapi';
import { Subscription } from 'rxjs';
import * as dayjs from 'dayjs';
import { MeteringPeriods } from '../../models/metering-periods.enum';
import { MeteringPeriodsService } from '../../services/metering-periods.service';
import { MeteringResolutionService } from '../../services/metering-resolution.service';

interface ResolutionOption {
  label: string;
  value: string;
}

@Component({
  selector: 'mp-metering-resolution-select',
  templateUrl: './metering-resolution-select.component.html'
})
export class MeteringResolutionSelectComponent implements OnInit, OnDestroy {

  @Input() energyType: EnergyType;
  @Input() emit: boolean;
  @Input() resolutionFilter: (res: MeteringResolution) => boolean = this.showResolution;
  @Output() setResolutionEvent: EventEmitter<MeteringResolution> = new EventEmitter();

  public currentResolution: MeteringResolution;
  public currentPeriodType: MeteringPeriods;
  public currentPeriodRange: MeteringDataTimeInterval;
  public resolutions: typeof MeteringResolution = MeteringResolution;
  public resolutionOptions: ResolutionOption[];

  private periodTypeSubscription: Subscription;
  private periodRangeSubscription: Subscription;
  private resolutionSubscription: Subscription;

  constructor(
    private periodsService: MeteringPeriodsService,
    private resolutionService: MeteringResolutionService
  ) { }

  ngOnInit(): void {
    this.periodTypeSubscription = this.periodsService.getPeriodType().subscribe((period) => {
      this.currentPeriodType = period;
      this.populateResolutionOptions();
    });
    this.periodRangeSubscription = this.periodsService.getPeriodRange().subscribe((range) => {
      this.currentPeriodRange = range;
      this.populateResolutionOptions();
    });

    // get current resolution
    this.resolutionSubscription = this.resolutionService.getResolution().subscribe((resolution) => {
      this.currentResolution = resolution;
    });
    // pop select options
    this.populateResolutionOptions();
  }

  ngOnDestroy(): void {
    if (this.periodTypeSubscription) {
      this.periodTypeSubscription.unsubscribe();
    }
    if (this.periodRangeSubscription) {
      this.periodRangeSubscription.unsubscribe();
    }
    if (this.resolutionSubscription) {
      this.resolutionSubscription.unsubscribe();
    }
  }

  public setResolution(value: MeteringResolution): void {
    this.currentResolution = value;
    if (this.emit) {
      this.setResolutionEvent.emit(value);
    } else {
      this.resolutionService.setResolution(value);
    }
  }

  public showResolution(resolution: MeteringResolution): boolean {
    if ((this.energyType === EnergyType.Gas) && resolution === MeteringResolution.FifteenMinutes) {
      return false;
    }
    switch (this.currentPeriodType) {
      case MeteringPeriods.Day:
        return resolution === MeteringResolution.FifteenMinutes
          || resolution === MeteringResolution.OneHour
          || resolution === MeteringResolution.OneDay;
      case MeteringPeriods.Week:
        return resolution === MeteringResolution.FifteenMinutes
          || resolution === MeteringResolution.OneHour
          || resolution === MeteringResolution.OneDay
          || resolution === MeteringResolution.OneWeek;
      case MeteringPeriods.Month:
        return resolution === MeteringResolution.FifteenMinutes
          || resolution === MeteringResolution.OneHour
          || resolution === MeteringResolution.OneDay
          || resolution === MeteringResolution.OneWeek
          || resolution === MeteringResolution.OneMonth;
      case MeteringPeriods.Year:
        return resolution === MeteringResolution.FifteenMinutes
          || resolution === MeteringResolution.OneHour
          || resolution === MeteringResolution.OneDay
          || resolution === MeteringResolution.OneWeek
          || resolution === MeteringResolution.OneMonth
          || resolution === MeteringResolution.OneYear;
      case MeteringPeriods.Custom: {
        if (this.currentPeriodRange?.from !== undefined) {
          const diff = dayjs(this.currentPeriodRange.from).diff(this.currentPeriodRange.to, 'year', true);
          if (Math.abs(diff) > 1.0) { // more than one year
            return resolution === MeteringResolution.OneDay
              || resolution === MeteringResolution.OneWeek
              || resolution === MeteringResolution.OneMonth
              || resolution === MeteringResolution.OneYear;
          }
        }
        return resolution === MeteringResolution.OneHour
          || resolution === MeteringResolution.OneDay
          || resolution === MeteringResolution.OneWeek
          || resolution === MeteringResolution.OneMonth
          || resolution === MeteringResolution.OneYear;
      }
    }
  }

  private populateResolutionOptions(): void {
    this.resolutionOptions = [];
    if (!this.resolutionFilter(this.currentResolution)) {
      this.currentResolution = this.getDefaultResolution();
    }
    Object.keys(this.resolutions).forEach((key: string) => {
      if (this.resolutionFilter(this.resolutions[key])) {
        this.resolutionOptions.push({
          label: `mp.resolution.${key.toLowerCase()}`,
          value: this.resolutions[key]
        });
      }
    });
  }

  private getDefaultResolution(): MeteringResolution {
    if (this.energyType === EnergyType.Gas) {
      // here we set resolution so api requests knowns that resolution has changed
      this.setResolution(this.resolutions.OneDay);
      return this.resolutions.OneDay;
    }
    return this.resolutions.OneHour; // default for electricity
  }
}
