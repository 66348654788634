import {
    Component, OnInit, OnDestroy, Input, Output, TemplateRef, EventEmitter
} from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ModalService } from '@elering/common';
import { EnergyType, MeteringResolution } from 'gen_openapi';
import { Subscription } from 'rxjs';
import * as dayjs from 'dayjs';
import { MeteringPeriodDatepickerService } from '../../views/metering-point/services/metering-period-datepicker.service';

export interface ExportOptions {
    from: string;
    to: string;
    resolution: MeteringResolution;
    showMarketPrice?: boolean;
}

@Component({
    selector: 'efkp-export',
    templateUrl: './export.component.html',
    styleUrls: ['./export.component.scss']
})
export class ExportComponent implements OnInit, OnDestroy {
    @Input() energyType: EnergyType;
    @Input() withoutPeriod: boolean;
    @Input() showLoader: boolean = false;
    @Input() allowLongHourlyPeriod: boolean = false;
    @Input() set period(date: string[]) {
        if (this.withoutPeriod) {
            return;
        }
        const [from, to] = date;
        this.currentPeriod = [new Date(from), new Date(to)];
    }
    @Input() allowCustomPeriod: boolean = true;
    @Input() showMarketPriceCheckbox: boolean = false;
    @Input() disabled: boolean = false;

    @Output() exportExcelEvent: EventEmitter<ExportOptions> = new EventEmitter();
    @Output() exportCsvEvent: EventEmitter<ExportOptions> = new EventEmitter();

    public modalRef?: BsModalRef;
    public currentPeriod: Date[];
    public datepickerMinDate: Date;
    public datepickerMaxDate: Date;
    public isOpen: boolean;

    private exportOptions: ExportOptions = {
        from: null, to: null, resolution: null, showMarketPrice: false
    };
    private subscription: Subscription;

    private validationError?: string;

    constructor(
        private modalService: ModalService,
        private datepickerService: MeteringPeriodDatepickerService
    ) { }

    ngOnInit(): void {
        if (this.withoutPeriod) {
            return;
        }

        this.subscription = this.datepickerService.getDatepickerConfig().subscribe((config) => {
            this.datepickerMinDate = config.minDate;
            this.datepickerMaxDate = config.maxDate;
        });
        this.setDate(this.currentPeriod);
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }

    public setDate(date: Date[]): void {
        const [from, to] = date;
        this.currentPeriod = date;
        this.exportOptions.from = from.toISOString();
        this.exportOptions.to = to.toISOString();
    }

    public setResolution(resolution: MeteringResolution): void {
        this.exportOptions.resolution = resolution;
    }

    public exportExcel(): void {
        this.validationError = this.validateExportOptions();
        if (!this.validationError) {
            this.exportExcelEvent.emit(this.exportOptions);
        }
    }

    public exportCsv(): void {
        this.validationError = this.validateExportOptions();
        if (!this.validationError) {
            this.exportCsvEvent.emit(this.exportOptions);
        }
    }

    public openModal(template: TemplateRef<unknown>, size): void {
        if (this.disabled) {
            return;
        }
        this.validationError = null;
        this.modalRef = this.modalService.showModal({
            content: template,
            size
        });
    }

    public closeModal(): void {
        this.modalService.closeActiveModal();
    }

    public showResolution(res: MeteringResolution): boolean {
        if (this.energyType === EnergyType.Gas) {
            return res !== MeteringResolution.FifteenMinutes;
        }
        return true;
    }

    public toggleIsOpen() {
        if (!this.disabled) {
            this.isOpen = !this.isOpen;
        }
    }

    private validateExportOptions(): string {
        if (!this.exportOptions.from || !this.exportOptions.to) {
            return null;
        }
        const from = dayjs(this.exportOptions.from).startOf('day');
        const to = dayjs(this.exportOptions.to).endOf('day');
        const res = this.exportOptions.resolution;
        if (res === MeteringResolution.OneWeek) {
            if (!from.startOf('week').isSame(from)) {
                return 'mp.export.validation_error.oneweek.from_must_be_at_the_start_of_week';
            }
            if (!to.endOf('week').isSame(to)) {
                return 'mp.export.validation_error.oneweek.to_must_be_at_the_end_of_week';
            }
        }
        if (res === MeteringResolution.OneMonth) {
            if (!from.startOf('month').isSame(from)) {
                return 'mp.export.validation_error.onemonth.from_must_be_at_the_start_of_month';
            }
            if (!to.endOf('month').isSame(to)) {
                return 'mp.export.validation_error.onemonth.to_must_be_at_the_end_of_month';
            }
        }
        if (res === MeteringResolution.OneYear) {
            if (!from.startOf('year').isSame(from)) {
                return 'mp.export.validation_error.oneyear.from_must_be_at_the_start_of_year';
            }
            if (!to.endOf('year').isSame(to)) {
                return 'mp.export.validation_error.oneyear.to_must_be_at_the_end_of_year';
            }
        }
        if (!this.allowLongHourlyPeriod && res === MeteringResolution.OneHour) {
            if (to.diff(from, 'day') > 366) {
                return 'mp.export.validation_error.onehour.electricity_period_too_long';
            }
        }
        return null;
    }

    showMarketPriceClicked($event: boolean) {
        this.exportOptions.showMarketPrice = $event;
    }
}
